$coreui-icons-prefix: "cuis-" !default;
$coreui-icons-font-path: "../fonts" !default;

@font-face {
  font-family: 'CoreUI-Icons-Solid';
  src:  url('#{$coreui-icons-font-path}/CoreUI-Icons-Solid.eot?ei2vov');
  src:  url('#{$coreui-icons-font-path}/CoreUI-Icons-Solid.eot?ei2vov#iefix') format('embedded-opentype'),
    url('#{$coreui-icons-font-path}/CoreUI-Icons-Solid.woff2?ei2vov') format('woff2'),
    url('#{$coreui-icons-font-path}/CoreUI-Icons-Solid.ttf?ei2vov') format('truetype'),
    url('#{$coreui-icons-font-path}/CoreUI-Icons-Solid.woff?ei2vov') format('woff'),
    url('#{$coreui-icons-font-path}/CoreUI-Icons-Solid.svg?ei2vov#CoreUI-Icons-Solid') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="#{$coreui-icons-prefix}"], [class*=" #{$coreui-icons-prefix}"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'CoreUI-Icons-Solid' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import "functions";
@import "icons-solid";

@each $icon, $unicode in $icons {
  .#{$coreui-icons-prefix}#{$icon} {
    &:before {
      content: unicode($unicode);
    }
  }
}
