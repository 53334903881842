@font-face {
  font-family: 'CoreUI-Icons-Solid';
  src: url("../fonts/CoreUI-Icons-Solid.eot?ei2vov");
  src: url("../fonts/CoreUI-Icons-Solid.eot?ei2vov#iefix") format("embedded-opentype"), url("../fonts/CoreUI-Icons-Solid.woff2?ei2vov") format("woff2"), url("../fonts/CoreUI-Icons-Solid.ttf?ei2vov") format("truetype"), url("../fonts/CoreUI-Icons-Solid.woff?ei2vov") format("woff"), url("../fonts/CoreUI-Icons-Solid.svg?ei2vov#CoreUI-Icons-Solid") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="cuis-"], [class*=" cuis-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'CoreUI-Icons-Solid' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cuis-accessible:before {
  content: "\e900";
}

.cuis-account-logout:before {
  content: "\e901";
}

.cuis-action-redo:before {
  content: "\e902";
}

.cuis-action-undo:before {
  content: "\e903";
}

.cuis-address-book:before {
  content: "\e904";
}

.cuis-address-card:before {
  content: "\e905";
}

.cuis-align-center:before {
  content: "\e906";
}

.cuis-align-left:before {
  content: "\e907";
}

.cuis-align-right:before {
  content: "\e908";
}

.cuis-aperture:before {
  content: "\e909";
}

.cuis-archive:before {
  content: "\e90a";
}

.cuis-arrow-bottom:before {
  content: "\e90b";
}

.cuis-arrow-circle-bottom:before {
  content: "\e90c";
}

.cuis-arrow-circle-left:before {
  content: "\e90d";
}

.cuis-arrow-circle-right:before {
  content: "\e90e";
}

.cuis-arrow-circle-top:before {
  content: "\e90f";
}

.cuis-arrow-left:before {
  content: "\e910";
}

.cuis-arrow-right:before {
  content: "\e911";
}

.cuis-arrow-thick-bottom:before {
  content: "\e912";
}

.cuis-arrow-thick-circle-down:before {
  content: "\e913";
}

.cuis-arrow-thick-circle-left:before {
  content: "\e914";
}

.cuis-arrow-thick-circle-right:before {
  content: "\e915";
}

.cuis-arrow-thick-circle-up:before {
  content: "\e916";
}

.cuis-arrow-thick-from-bottom:before {
  content: "\e917";
}

.cuis-arrow-thick-from-left:before {
  content: "\e918";
}

.cuis-arrow-thick-from-right:before {
  content: "\e919";
}

.cuis-arrow-thick-from-top:before {
  content: "\e91a";
}

.cuis-arrow-thick-left:before {
  content: "\e91b";
}

.cuis-arrow-thick-right:before {
  content: "\e91c";
}

.cuis-arrow-thick-square-down:before {
  content: "\e91d";
}

.cuis-arrow-thick-square-left:before {
  content: "\e91e";
}

.cuis-arrow-thick-square-right:before {
  content: "\e91f";
}

.cuis-arrow-thick-square-up:before {
  content: "\e920";
}

.cuis-arrow-thick-to-bottom:before {
  content: "\e921";
}

.cuis-arrow-thick-to-left:before {
  content: "\e922";
}

.cuis-arrow-thick-to-right:before {
  content: "\e924";
}

.cuis-arrow-thick-to-top:before {
  content: "\e925";
}

.cuis-arrow-thick-top:before {
  content: "\e923";
}

.cuis-arrow-top:before {
  content: "\e926";
}

.cuis-assistive-listening-system:before {
  content: "\e927";
}

.cuis-at:before {
  content: "\e928";
}

.cuis-audio:before {
  content: "\e929";
}

.cuis-audio-description:before {
  content: "\e92a";
}

.cuis-audio-spectrum:before {
  content: "\e92b";
}

.cuis-badge:before {
  content: "\e92c";
}

.cuis-balance-scale:before {
  content: "\e92d";
}

.cuis-balance-scale-left:before {
  content: "\e92e";
}

.cuis-balance-scale-right:before {
  content: "\e92f";
}

.cuis-ban:before {
  content: "\e930";
}

.cuis-bar-chart:before {
  content: "\e931";
}

.cuis-barcode:before {
  content: "\e932";
}

.cuis-bath:before {
  content: "\e934";
}

.cuis-battery-empty:before {
  content: "\e935";
}

.cuis-battery-full:before {
  content: "\e936";
}

.cuis-beaker:before {
  content: "\e937";
}

.cuis-bell:before {
  content: "\e938";
}

.cuis-bell-slash:before {
  content: "\e939";
}

.cuis-blind:before {
  content: "\e93a";
}

.cuis-bluetooth:before {
  content: "\e93b";
}

.cuis-bold:before {
  content: "\e93c";
}

.cuis-bolt:before {
  content: "\e93d";
}

.cuis-book:before {
  content: "\e93e";
}

.cuis-book-open:before {
  content: "\e940";
}

.cuis-bookmark:before {
  content: "\e93f";
}

.cuis-box:before {
  content: "\e941";
}

.cuis-braille:before {
  content: "\e942";
}

.cuis-briefcase:before {
  content: "\e943";
}

.cuis-british-pound:before {
  content: "\e944";
}

.cuis-broadcast-tower:before {
  content: "\e945";
}

.cuis-browser:before {
  content: "\e946";
}

.cuis-brush:before {
  content: "\e947";
}

.cuis-bug:before {
  content: "\e948";
}

.cuis-building:before {
  content: "\e949";
}

.cuis-bullhorn:before {
  content: "\e94a";
}

.cuis-calculator:before {
  content: "\e94b";
}

.cuis-calendar:before {
  content: "\e94c";
}

.cuis-calendar-check:before {
  content: "\e94d";
}

.cuis-calendar-minus:before {
  content: "\e94e";
}

.cuis-calendar-plus:before {
  content: "\e94f";
}

.cuis-calendar-times:before {
  content: "\e950";
}

.cuis-camera:before {
  content: "\e951";
}

.cuis-caret-bottom:before {
  content: "\e952";
}

.cuis-caret-left:before {
  content: "\e953";
}

.cuis-caret-right:before {
  content: "\e954";
}

.cuis-caret-top:before {
  content: "\e955";
}

.cuis-cart:before {
  content: "\e956";
}

.cuis-cart-arrow-down:before {
  content: "\e957";
}

.cuis-cart-loaded:before {
  content: "\e933";
}

.cuis-chalkboard:before {
  content: "\e958";
}

.cuis-chalkboard-teacher:before {
  content: "\e959";
}

.cuis-chart:before {
  content: "\e95a";
}

.cuis-chart-area:before {
  content: "\e95b";
}

.cuis-chart-line:before {
  content: "\e95c";
}

.cuis-chart-pie:before {
  content: "\ea54";
}

.cuis-chat:before {
  content: "\e95d";
}

.cuis-chat-bubble:before {
  content: "\e95e";
}

.cuis-check:before {
  content: "\e95f";
}

.cuis-chevron-bottom:before {
  content: "\e960";
}

.cuis-chevron-circle-down:before {
  content: "\e961";
}

.cuis-chevron-circle-left:before {
  content: "\e962";
}

.cuis-chevron-circle-right:before {
  content: "\e963";
}

.cuis-chevron-circle-up:before {
  content: "\e964";
}

.cuis-chevron-double-down:before {
  content: "\e965";
}

.cuis-chevron-double-left:before {
  content: "\e966";
}

.cuis-chevron-double-right:before {
  content: "\e967";
}

.cuis-chevron-double-up:before {
  content: "\e968";
}

.cuis-chevron-left:before {
  content: "\e969";
}

.cuis-chevron-right:before {
  content: "\e96a";
}

.cuis-chevron-square-down:before {
  content: "\e96b";
}

.cuis-chevron-square-left:before {
  content: "\e96c";
}

.cuis-chevron-square-right:before {
  content: "\e96d";
}

.cuis-chevron-square-up:before {
  content: "\e96e";
}

.cuis-chevron-top:before {
  content: "\e96f";
}

.cuis-circle:before {
  content: "\e970";
}

.cuis-circle-check:before {
  content: "\e971";
}

.cuis-circle-x:before {
  content: "\e972";
}

.cuis-clipboard:before {
  content: "\e973";
}

.cuis-clock:before {
  content: "\e974";
}

.cuis-clone:before {
  content: "\e975";
}

.cuis-closed-captioning:before {
  content: "\e976";
}

.cuis-cloud:before {
  content: "\e977";
}

.cuis-cloud-download:before {
  content: "\e978";
}

.cuis-cloud-upload:before {
  content: "\e979";
}

.cuis-cloudy:before {
  content: "\e97a";
}

.cuis-code:before {
  content: "\e97b";
}

.cuis-coffee:before {
  content: "\e97c";
}

.cuis-cog:before {
  content: "\e97d";
}

.cuis-collapse-down:before {
  content: "\e97e";
}

.cuis-collapse-left:before {
  content: "\e97f";
}

.cuis-collapse-right:before {
  content: "\e980";
}

.cuis-collapse-up:before {
  content: "\e981";
}

.cuis-columns:before {
  content: "\e982";
}

.cuis-command:before {
  content: "\e983";
}

.cuis-comment-bubble:before {
  content: "\e984";
}

.cuis-comment-bubble-check:before {
  content: "\e985";
}

.cuis-comment-bubble-edit:before {
  content: "\e986";
}

.cuis-comment-bubble-exclamation:before {
  content: "\e987";
}

.cuis-comment-bubble-lines:before {
  content: "\e988";
}

.cuis-comment-bubble-minus:before {
  content: "\e989";
}

.cuis-comment-bubble-plus:before {
  content: "\e98a";
}

.cuis-comment-bubble-smile:before {
  content: "\e98b";
}

.cuis-comment-bubble-x:before {
  content: "\e98c";
}

.cuis-comment-square-exclamation:before {
  content: "\e98d";
}

.cuis-comment-square-lines:before {
  content: "\e98e";
}

.cuis-comment-square-minus:before {
  content: "\e98f";
}

.cuis-comment-square-plus:before {
  content: "\e990";
}

.cuis-comment-square-smile:before {
  content: "\e991";
}

.cuis-comment-square:before {
  content: "\e992";
}

.cuis-comment-square-check:before {
  content: "\e993";
}

.cuis-comment-square-edit:before {
  content: "\e994";
}

.cuis-comment-square-x:before {
  content: "\e995";
}

.cuis-compass:before {
  content: "\e996";
}

.cuis-compress:before {
  content: "\e997";
}

.cuis-compress-wide:before {
  content: "\e998";
}

.cuis-contrast:before {
  content: "\e999";
}

.cuis-copy:before {
  content: "\e99a";
}

.cuis-copyright:before {
  content: "\e99b";
}

.cuis-copywriting:before {
  content: "\e99c";
}

.cuis-credit-card:before {
  content: "\e99d";
}

.cuis-crop:before {
  content: "\e99e";
}

.cuis-cursor:before {
  content: "\e99f";
}

.cuis-cursor-move:before {
  content: "\e9a0";
}

.cuis-cut:before {
  content: "\e9a1";
}

.cuis-dashboard:before {
  content: "\e9a2";
}

.cuis-data-transfer-download:before {
  content: "\e9a3";
}

.cuis-data-transfer-upload:before {
  content: "\e9a4";
}

.cuis-deaf:before {
  content: "\e9a5";
}

.cuis-delete:before {
  content: "\e9a6";
}

.cuis-dial:before {
  content: "\e9a7";
}

.cuis-document:before {
  content: "\e9a8";
}

.cuis-dollar:before {
  content: "\e9a9";
}

.cuis-donate:before {
  content: "\e9aa";
}

.cuis-double-quote-sans-left:before {
  content: "\e9ab";
}

.cuis-double-quote-sans-right:before {
  content: "\e9ac";
}

.cuis-double-quote-serif-left:before {
  content: "\e9ad";
}

.cuis-double-quote-serif-right:before {
  content: "\e9ae";
}

.cuis-drop:before {
  content: "\e9af";
}

.cuis-eject:before {
  content: "\e9b0";
}

.cuis-elevator:before {
  content: "\e9b1";
}

.cuis-ellipses:before {
  content: "\e9b2";
}

.cuis-energy:before {
  content: "\e9b3";
}

.cuis-envelope-closed:before {
  content: "\e9b4";
}

.cuis-envelope-letter:before {
  content: "\e9b5";
}

.cuis-envelope-open:before {
  content: "\e9b6";
}

.cuis-equalizer:before {
  content: "\e9b7";
}

.cuis-eraser:before {
  content: "\e9b8";
}

.cuis-euro:before {
  content: "\e9b9";
}

.cuis-excerpt:before {
  content: "\e9ba";
}

.cuis-expand-down:before {
  content: "\e9bb";
}

.cuis-expand-left:before {
  content: "\e9bc";
}

.cuis-expand-right:before {
  content: "\e9bd";
}

.cuis-expand-up:before {
  content: "\e9be";
}

.cuis-external-link:before {
  content: "\e9bf";
}

.cuis-eye:before {
  content: "\e9c0";
}

.cuis-eye-slash:before {
  content: "\e9c2";
}

.cuis-eyedropper:before {
  content: "\e9c1";
}

.cuis-fax:before {
  content: "\e9c3";
}

.cuis-file:before {
  content: "\e9c4";
}

.cuis-file-acrobat:before {
  content: "\e9c5";
}

.cuis-file-archive:before {
  content: "\e9c6";
}

.cuis-file-audio:before {
  content: "\e9c7";
}

.cuis-file-code:before {
  content: "\e9c8";
}

.cuis-file-doc:before {
  content: "\e9c9";
}

.cuis-file-excel:before {
  content: "\e9ca";
}

.cuis-file-image:before {
  content: "\e9cb";
}

.cuis-file-pdf:before {
  content: "\e9cc";
}

.cuis-file-powerpoint:before {
  content: "\e9cd";
}

.cuis-file-ppt:before {
  content: "\e9ce";
}

.cuis-file-video:before {
  content: "\e9cf";
}

.cuis-file-word:before {
  content: "\e9d0";
}

.cuis-file-xls:before {
  content: "\e9d1";
}

.cuis-film:before {
  content: "\e9d2";
}

.cuis-filter:before {
  content: "\e9d3";
}

.cuis-fire:before {
  content: "\e9d4";
}

.cuis-flag:before {
  content: "\e9d5";
}

.cuis-flash:before {
  content: "\e9d6";
}

.cuis-folder:before {
  content: "\e9d7";
}

.cuis-folder-open:before {
  content: "\e9d8";
}

.cuis-fork:before {
  content: "\e9d9";
}

.cuis-frown:before {
  content: "\e9da";
}

.cuis-fullscreen-enter:before {
  content: "\e9db";
}

.cuis-fullscreen-exit:before {
  content: "\e9dc";
}

.cuis-futbol:before {
  content: "\e9dd";
}

.cuis-gem:before {
  content: "\e9de";
}

.cuis-gift:before {
  content: "\e9df";
}

.cuis-glasses:before {
  content: "\e9e0";
}

.cuis-globe:before {
  content: "\e9e1";
}

.cuis-graph:before {
  content: "\e9e2";
}

.cuis-grid-four-up:before {
  content: "\e9e3";
}

.cuis-grid-three-up:before {
  content: "\e9e4";
}

.cuis-grid-two-up:before {
  content: "\e9e5";
}

.cuis-hand-lizard:before {
  content: "\e9e6";
}

.cuis-hand-paper:before {
  content: "\e9e7";
}

.cuis-hand-peace:before {
  content: "\e9e8";
}

.cuis-hand-point-down:before {
  content: "\e9e9";
}

.cuis-hand-point-left:before {
  content: "\e9eb";
}

.cuis-hand-point-right:before {
  content: "\e9ec";
}

.cuis-hand-point-up:before {
  content: "\e9ed";
}

.cuis-hand-pointer:before {
  content: "\e9ea";
}

.cuis-hand-rock:before {
  content: "\e9ee";
}

.cuis-hand-scissors:before {
  content: "\e9ef";
}

.cuis-hand-spock:before {
  content: "\e9f1";
}

.cuis-handshake:before {
  content: "\e9f0";
}

.cuis-hard-drive:before {
  content: "\e9f2";
}

.cuis-hdd:before {
  content: "\e9f3";
}

.cuis-header:before {
  content: "\e9f4";
}

.cuis-headphones:before {
  content: "\e9f5";
}

.cuis-heart:before {
  content: "\e9f6";
}

.cuis-highlighter:before {
  content: "\e9f7";
}

.cuis-history:before {
  content: "\e9f8";
}

.cuis-home:before {
  content: "\e9f9";
}

.cuis-hospital:before {
  content: "\e9fa";
}

.cuis-hourglass:before {
  content: "\e9fb";
}

.cuis-id-badge:before {
  content: "\e9fc";
}

.cuis-id-card:before {
  content: "\e9fd";
}

.cuis-image:before {
  content: "\e9fe";
}

.cuis-images:before {
  content: "\e9ff";
}

.cuis-inbox:before {
  content: "\ea00";
}

.cuis-inbox-in:before {
  content: "\ea01";
}

.cuis-inbox-out:before {
  content: "\ea02";
}

.cuis-industry:before {
  content: "\ea03";
}

.cuis-infinity:before {
  content: "\ea04";
}

.cuis-info:before {
  content: "\ea05";
}

.cuis-italic:before {
  content: "\ea06";
}

.cuis-justify-center:before {
  content: "\ea07";
}

.cuis-justify-left:before {
  content: "\ea08";
}

.cuis-justify-right:before {
  content: "\ea09";
}

.cuis-key:before {
  content: "\ea0a";
}

.cuis-keyboard:before {
  content: "\ea0b";
}

.cuis-laptop:before {
  content: "\ea0c";
}

.cuis-layers:before {
  content: "\ea0d";
}

.cuis-lemon:before {
  content: "\ea0e";
}

.cuis-level-down:before {
  content: "\ea0f";
}

.cuis-level-up:before {
  content: "\ea10";
}

.cuis-life-ring:before {
  content: "\ea11";
}

.cuis-lightbulb:before {
  content: "\ea12";
}

.cuis-link-broken:before {
  content: "\ea13";
}

.cuis-link-intact:before {
  content: "\ea14";
}

.cuis-list:before {
  content: "\ea15";
}

.cuis-list-rich:before {
  content: "\ea16";
}

.cuis-location-pin:before {
  content: "\ea17";
}

.cuis-lock-locked:before {
  content: "\ea18";
}

.cuis-lock-unlocked:before {
  content: "\ea19";
}

.cuis-loop:before {
  content: "\ea1a";
}

.cuis-loop-1:before {
  content: "\ea1b";
}

.cuis-loop-circular:before {
  content: "\ea1c";
}

.cuis-loop-square:before {
  content: "\ea1d";
}

.cuis-low-vision:before {
  content: "\ea1e";
}

.cuis-magnifying-glass:before {
  content: "\ea1f";
}

.cuis-map:before {
  content: "\ea20";
}

.cuis-map-alt:before {
  content: "\ea21";
}

.cuis-media-pause:before {
  content: "\ea22";
}

.cuis-media-play:before {
  content: "\ea23";
}

.cuis-media-record:before {
  content: "\ea24";
}

.cuis-media-skip-backward:before {
  content: "\ea25";
}

.cuis-media-skip-forward:before {
  content: "\ea26";
}

.cuis-media-step-backward:before {
  content: "\ea27";
}

.cuis-media-step-forward:before {
  content: "\ea28";
}

.cuis-media-stop:before {
  content: "\ea29";
}

.cuis-medical-cross:before {
  content: "\ea2a";
}

.cuis-meh:before {
  content: "\ea2b";
}

.cuis-menu:before {
  content: "\ea2c";
}

.cuis-microchip:before {
  content: "\ea2d";
}

.cuis-microphone:before {
  content: "\ea2e";
}

.cuis-microphone-slash:before {
  content: "\ea2f";
}

.cuis-minus:before {
  content: "\ea30";
}

.cuis-minus-square:before {
  content: "\ea31";
}

.cuis-mobile:before {
  content: "\ea48";
}

.cuis-mobile-check:before {
  content: "\ea4f";
}

.cuis-mobile-plus:before {
  content: "\ea50";
}

.cuis-mobile-slash:before {
  content: "\ea51";
}

.cuis-mobile-volume:before {
  content: "\ea52";
}

.cuis-mobile-x:before {
  content: "\ea53";
}

.cuis-money-bill-alt:before {
  content: "\ea32";
}

.cuis-monitor:before {
  content: "\ea33";
}

.cuis-moon:before {
  content: "\ea34";
}

.cuis-move:before {
  content: "\ea35";
}

.cuis-musical-note:before {
  content: "\ea36";
}

.cuis-newspaper:before {
  content: "\ea37";
}

.cuis-note:before {
  content: "\ea38";
}

.cuis-notebook:before {
  content: "\ea39";
}

.cuis-object-group:before {
  content: "\ea3a";
}

.cuis-object-ungroup:before {
  content: "\ea3b";
}

.cuis-options:before {
  content: "\ea3c";
}

.cuis-paper-plane:before {
  content: "\ea3e";
}

.cuis-paperclip:before {
  content: "\ea3d";
}

.cuis-pause-circle:before {
  content: "\ea3f";
}

.cuis-pen:before {
  content: "\ea40";
}

.cuis-pen-alt:before {
  content: "\ea41";
}

.cuis-pen-fancy:before {
  content: "\ea43";
}

.cuis-pen-nib:before {
  content: "\ea44";
}

.cuis-pencil:before {
  content: "\ea42";
}

.cuis-people:before {
  content: "\ea45";
}

.cuis-percent:before {
  content: "\ea46";
}

.cuis-person:before {
  content: "\ea47";
}

.cuis-phone-call:before {
  content: "\eac2";
}

.cuis-phone-check:before {
  content: "\ea49";
}

.cuis-phone-plus:before {
  content: "\ea4a";
}

.cuis-phone-slash:before {
  content: "\ea4b";
}

.cuis-phone-square:before {
  content: "\ea4c";
}

.cuis-phone-volume:before {
  content: "\ea4d";
}

.cuis-phone-x:before {
  content: "\ea4e";
}

.cuis-pin:before {
  content: "\ea55";
}

.cuis-play-circle:before {
  content: "\ea56";
}

.cuis-plus:before {
  content: "\ea57";
}

.cuis-plus-square:before {
  content: "\ea58";
}

.cuis-power-standby:before {
  content: "\ea59";
}

.cuis-print:before {
  content: "\ea5a";
}

.cuis-project:before {
  content: "\ea5b";
}

.cuis-pulse:before {
  content: "\ea5c";
}

.cuis-puzzle:before {
  content: "\ea5d";
}

.cuis-puzzle-piece:before {
  content: "\ea5e";
}

.cuis-qr-code:before {
  content: "\ea5f";
}

.cuis-question-circle:before {
  content: "\ea60";
}

.cuis-question-mark:before {
  content: "\ea61";
}

.cuis-rain:before {
  content: "\ea62";
}

.cuis-random:before {
  content: "\ea63";
}

.cuis-rectangle-wide:before {
  content: "\ea64";
}

.cuis-recycle:before {
  content: "\ea65";
}

.cuis-registered:before {
  content: "\ea66";
}

.cuis-reload:before {
  content: "\ea67";
}

.cuis-resize-both:before {
  content: "\ea68";
}

.cuis-resize-height:before {
  content: "\ea69";
}

.cuis-resize-width:before {
  content: "\ea6a";
}

.cuis-rss:before {
  content: "\ea6b";
}

.cuis-rss-alt:before {
  content: "\ea6c";
}

.cuis-rss-square:before {
  content: "\ea6d";
}

.cuis-save:before {
  content: "\ea6e";
}

.cuis-screen-desktop:before {
  content: "\ea6f";
}

.cuis-screen-smartphone:before {
  content: "\ea70";
}

.cuis-script:before {
  content: "\ea71";
}

.cuis-scrubber:before {
  content: "\ea72";
}

.cuis-settings:before {
  content: "\ea73";
}

.cuis-share:before {
  content: "\ea74";
}

.cuis-share-all:before {
  content: "\ea75";
}

.cuis-share-boxed:before {
  content: "\ea76";
}

.cuis-shield:before {
  content: "\ea77";
}

.cuis-shield-check:before {
  content: "\ea78";
}

.cuis-sign-language:before {
  content: "\ea7a";
}

.cuis-signal:before {
  content: "\ea79";
}

.cuis-signpost:before {
  content: "\ea7b";
}

.cuis-sitemap:before {
  content: "\ea7c";
}

.cuis-smile:before {
  content: "\ea7d";
}

.cuis-smile-plus:before {
  content: "\ea7e";
}

.cuis-snowflake:before {
  content: "\ea7f";
}

.cuis-social-facebook:before {
  content: "\ea80";
}

.cuis-social-linkedin:before {
  content: "\ea81";
}

.cuis-social-skype:before {
  content: "\ea82";
}

.cuis-social-spotify:before {
  content: "\ea83";
}

.cuis-social-twitter:before {
  content: "\ea84";
}

.cuis-sort-alpha-down:before {
  content: "\ea85";
}

.cuis-sort-alpha-up:before {
  content: "\ea86";
}

.cuis-sort-ascending:before {
  content: "\ea87";
}

.cuis-sort-descending:before {
  content: "\ea88";
}

.cuis-sort-numeric-down:before {
  content: "\ea89";
}

.cuis-sort-numeric-up:before {
  content: "\ea8a";
}

.cuis-speech:before {
  content: "\ea8b";
}

.cuis-speech-bubble:before {
  content: "\ea8c";
}

.cuis-speedometer:before {
  content: "\ea8d";
}

.cuis-spreadsheet:before {
  content: "\ea8e";
}

.cuis-square:before {
  content: "\ea8f";
}

.cuis-star:before {
  content: "\ea90";
}

.cuis-star-half:before {
  content: "\ea91";
}

.cuis-sticky-note:before {
  content: "\ea92";
}

.cuis-stop-circle:before {
  content: "\ea93";
}

.cuis-stream:before {
  content: "\ea94";
}

.cuis-sun:before {
  content: "\ea95";
}

.cuis-tablet:before {
  content: "\ea96";
}

.cuis-tag:before {
  content: "\ea97";
}

.cuis-tags:before {
  content: "\ea98";
}

.cuis-target:before {
  content: "\ea99";
}

.cuis-task:before {
  content: "\ea9a";
}

.cuis-terminal:before {
  content: "\ea9b";
}

.cuis-text:before {
  content: "\ea9c";
}

.cuis-text-height:before {
  content: "\ea9d";
}

.cuis-text-width:before {
  content: "\ea9e";
}

.cuis-thumb-down:before {
  content: "\ea9f";
}

.cuis-thumb-up:before {
  content: "\eaa0";
}

.cuis-timer:before {
  content: "\eaa1";
}

.cuis-trademark:before {
  content: "\eaa2";
}

.cuis-transfer:before {
  content: "\eaa3";
}

.cuis-trash:before {
  content: "\eaa4";
}

.cuis-triangle:before {
  content: "\eaa5";
}

.cuis-tty:before {
  content: "\eaa6";
}

.cuis-underline:before {
  content: "\eaa7";
}

.cuis-universal-access:before {
  content: "\eaa8";
}

.cuis-user:before {
  content: "\eaa9";
}

.cuis-user-female:before {
  content: "\eaaa";
}

.cuis-user-follow:before {
  content: "\eaab";
}

.cuis-user-secret:before {
  content: "\eaac";
}

.cuis-user-unfollow:before {
  content: "\eaad";
}

.cuis-vertical-align-bottom:before {
  content: "\eaae";
}

.cuis-vertical-align-center:before {
  content: "\eaaf";
}

.cuis-vertical-align-top:before {
  content: "\eab0";
}

.cuis-video:before {
  content: "\eab1";
}

.cuis-video-plus:before {
  content: "\eab2";
}

.cuis-video-slash:before {
  content: "\eab3";
}

.cuis-volume-high:before {
  content: "\eab4";
}

.cuis-volume-low:before {
  content: "\eab5";
}

.cuis-volume-off:before {
  content: "\eab6";
}

.cuis-wallet:before {
  content: "\eab7";
}

.cuis-warning:before {
  content: "\eab8";
}

.cuis-wheelchair:before {
  content: "\eab9";
}

.cuis-wifi:before {
  content: "\eaba";
}

.cuis-window-minimize:before {
  content: "\eabb";
}

.cuis-window-restore:before {
  content: "\eabc";
}

.cuis-wrench:before {
  content: "\eabd";
}

.cuis-x:before {
  content: "\eabe";
}

.cuis-yen:before {
  content: "\eabf";
}

.cuis-zoom-in:before {
  content: "\eac0";
}

.cuis-zoom-out:before {
  content: "\eac1 ";
}

/*# sourceMappingURL=coreui-icons-solid.css.map */